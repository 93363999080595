<template>
  <div class="shortapp__container container">
    <div class="form-container">
      <div class="step first box-input" id="step1" v-show="step==1" @click="goNext(2)">
        <div class="headline" data-name="Purpose">
          TELL US ABOUT YOU
        </div>
        <div class="box refinance">
          <img class="icon" src="assets/images/icon_stop.png" alt="stop">
          <div class="btn-txt">Start</div>
        </div>
      </div>

      <div class="step box-input optional" id="step2" v-show="step==2">
        <div class="headline" data-name="How to Reach">
          What's the best way to reach you?
        </div>
        <div class="box-wrap--80">
          <div class="box odd-display" @click="chooseReachMethod(0)">
            <img class="icon emoji" src="assets/images/Mobile.png" alt="mobile">
            <p class="txt">Mobile</p>
          </div>
          <div class="box" @click="chooseReachMethod(1)">
            <img class="icon emoji" src="assets/images/Home.png" alt="home">
            <p class="txt">Home</p>
          </div>
          <div class="box work-box" @click="chooseReachMethod(2)">
            <img class="icon emoji" src="assets/images/Work.png" alt="work">
            <p class="txt">Work</p>
          </div>
          <div class="box active box-email" @click="chooseReachMethod(3)">
            <img class="icon emoji" src="assets/images/Email.png" alt="email">
            <p class="txt">E-mail</p>
          </div>
        </div>

      </div>


      <div class="step box-input armed-forces optional" id="step3" v-show="step==3">
        <div class="headline" data-name="Armed Forces">
          Have you served in the armed forces?
        </div>
        <div class="box" data-show=".armed-forces--yes" @click="servedArmy(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" data-hide=".armed-forces--yes" @click="servedArmy(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step optional box-input armed-forces armed-forces--yes" id="step4" v-show="step==4">
        <div class="headline" data-name="Armed Forces Branch">Which Branch?</div>
        <div class="box" @click="chooseArmyBranch(0)"><img src="assets/images/army-badge.png" alt="Army" class="icon">
          <p class="txt">Army</p>
        </div>
        <div class="box" @click="chooseArmyBranch(1)"><img src="assets/images/marine-badge.png" alt="Marines"
            class="icon">
          <p class="txt">Marines</p>
        </div>
        <div class="box active" @click="chooseArmyBranch(2)"><img src="assets/images/navy-badge.png" alt="Navy"
            class="icon">
          <p class="txt">Navy</p>
        </div>
        <div class="box" @click="chooseArmyBranch(3)"><img src="assets/images/airforce-badge.png" alt="Air force"
            class="icon">
          <p class="txt">Air Force</p>
        </div>
        <div class="box" @click="chooseArmyBranch(4)"><img src="assets/images/coastguard-badge.jpg" alt="Coast Guard"
            class="icon">
          <p class="txt">Coast Guard</p>
        </div>
      </div>

      <div class="step box-input p downpayment" id="step5" v-show="step==5">
        <div class="headline">
          MARRIED?
        </div>
        <div class="box" @click="marriedCheck(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="marriedCheck(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step text-input" id="step6" v-show="step==6">
        <div class="headline">
          SPOUSE'S NAME?
        </div>
        <div>
          <input name="spouse_name" type="text" class="textfield optional" placeholder="Spouses name"
            aria-label="Spouses name" v-model="spouseName">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(7)">Continue</button>
      </div>

      <div class="step text-input" id="step7" v-show="step==7">
        <div class="headline">
          WEDDING DATE?
        </div>
        <div>
          <input name="wedding_date" type="text" class="textfield optional" placeholder="MM-DD-YYYY"
            aria-label="Wedding Date" v-model="weddingDate">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(8)">Continue</button>
      </div>

      <div class="step text-input" id="step8" v-show="step==8">
        <div class="headline">
          SPOUSE'S BIRTHDAY?
        </div>
        <div>
          <input name="spouse_birthday" type="text" class="textfield optional" placeholder="MM-DD-YYYY"
            aria-label="Spouse Birthday" v-model="spouseBirthday">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(9)">Continue</button>
      </div>


      <div class="step box-input p downpayment" id="step9" v-show="step==9">
        <div class="headline">
          DO YOU HAVE ANY CHILDREN UNDER THE AGE OF 21?
        </div>
        <div class="box" @click="childCheck(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="childCheck(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step text-input" id="step10" v-show="step==10">
        <div class="headline">
          WHAT ARE THEIR NAMES AND AGES?
        </div>
        <div class="row">
          <div class="col-xs-12">
            <input name="ChildName1" id="ChildName1" type="text" class="textfield form-control optional"
              placeholder="Full Name" style="display: inline-block" aria-label="full name" v-model="child.childName1">
            <input name="ChildBirthday1" id="ChildBirthday1" type="text"
              class="textfield form-control optional masked-date-input" placeholder="Age"
              style="display: inline-block; width: 120px;" aria-label="child birthday" v-model="child.childBirthday1">
          </div>
          <div class="col-xs-12">
            <input name="ChildName2" id="ChildName2" type="text" class="textfield form-control optional"
              placeholder="Full Name" style="display: inline-block" aria-label="full name" v-model="child.childName2">
            <input name="ChildBirthday2" id="ChildBirthday2" type="text"
              class="textfield form-control optional masked-date-input" placeholder="Age"
              style="display: inline-block; width: 120px;" aria-label="child birthday" v-model="child.childBirthday2">
          </div>
          <div class="col-xs-12">
            <input name="ChildName3" id="ChildName3" type="text" class="textfield form-control optional"
              placeholder="Full Name" style="display: inline-block" aria-label="full name" v-model="child.childName3">
            <input name="ChildBirthday3" id="ChildBirthday3" type="text"
              class="textfield form-control optional masked-date-input" placeholder="Age"
              style="display: inline-block; width: 120px;" aria-label="child birthdate" v-model="child.childBirthday3">
          </div>
          <div class="col-xs-12">
            <input name="ChildName4" id="ChildName4" type="text" class="textfield form-control optional"
              placeholder="Full Name" style="display: inline-block" aria-label="full name" v-model="child.childName4">
            <input name="ChildBirthday4" id="ChildBirthday4" type="text"
              class="textfield form-control optional masked-date-input" placeholder="Age"
              style="display: inline-block; width: 120px;" aria-label="child birthdate" v-model="child.childBirthday4">
          </div>
          <div class="col-xs-12">
            <input name="ChildName5" id="ChildName5" type="text" class="textfield form-control optional"
              placeholder="Full Name" style="display: inline-block" aria-label="full name" v-model="child.childName5">
            <input name="ChildBirthday5" id="ChildBirthday5" type="text"
              class="textfield form-control optional masked-date-input" placeholder="Age"
              style="display: inline-block; width: 120px;" aria-label="child birthdate" v-model="child.childBirthday5">
          </div>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(11)">Continue</button>
      </div>

      <div class="step text-input" id="step11" v-show="step==11">
        <div class="headline">
          WHAT IS YOUR FAVORITE CHARITY?
        </div>
        <div>
          <input name="favorite_charity" type="text" class="textfield optional" placeholder="Favorite Charity"
            aria-label="Favorite Charity" v-model="favoriteCharity">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(12)">Continue</button>
      </div>


      <div class="step box-input p downpayment" id="step12" v-show="step==12">
        <div class="headline">
          ARE YOU A FAN OF COLLEGE FOOTBALL?
        </div>
        <div class="box" @click="cFbCheck(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="cFbCheck(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step text-input" id="step13" v-show="step==13">
        <div class="headline">
          WHAT TEAM?
        </div>
        <div>
          <input name="collage_team" type="text" class="textfield optional" placeholder="Team Name"
            aria-label="Team Name" v-model="collageTeam">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(14)">Continue</button>
      </div>

      <div class="step box-input p downpayment" id="step14" v-show="step==14">
        <div class="headline">
          ARE YOU A FAN OF NFL FOOTBALL?
        </div>
        <div class="box" @click="nflCheck(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="nflCheck(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step text-input" id="step15" v-show="step==15">
        <div class="headline">
          WHAT TEAM?
        </div>
        <div>
          <input name="nfl_team" type="text" class="textfield optional" placeholder="Team Name" aria-label="Team Name"
            v-model="nflTeam">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(16)">Continue</button>
      </div>

      <div class="step text-input" id="step16" v-show="step==16">
        <div class="headline">
          WHAT ARE YOUR FAVORITE HOBBIES?
        </div>
        <div>
          <input name="favorite_hobby1" type="text" class="textfield optional" placeholder="Favorite Hobby 1"
            aria-label="Favorite Hobby 1" v-model="favoriteHobby1">
          <br>
          <input name="favorite_hobby2" type="text" class="textfield optional" placeholder="Favorite Hobby 2"
            aria-label="Favorite Hobby 2" v-model="favoriteHobby2">
          <br>
          <input name="favorite_hobby3" type="text" class="textfield optional" placeholder="Favorite Hobby 3"
            aria-label="Favorite Hobby 3" v-model="favoriteHobby3">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(17)">Continue</button>
      </div>

      <div class="step text-input" id="step17" v-show="step==17">
        <div class="headline">
          FAVORITE LUNCH RESTAURANT?
        </div>
        <div>
          <input name="lunch_resturant" type="text" class="textfield optional" placeholder="Restaurant"
            aria-label="Restaurant" v-model="favoriteLunch">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(18)">Continue</button>
      </div>

      <div class="step text-input" id="step18" v-show="step==18">
        <div class="headline">
          FAVORITE DINNER RESTAURANT?
        </div>
        <div>
          <input name="dinner_resturant" type="text" class="textfield optional" placeholder="Restaurant"
            aria-label="Restaurant" v-model="favoriteDinner">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(19)">Continue</button>
      </div>




      <div class="step text-input" id="step19" v-show="step==19">
        <div class="headline">
          WHAT IS YOUR FAVORITE FOOD TREAT?
        </div>
        <div>
          <input name="food_treat" type="text" class="textfield optional" placeholder="Treat" aria-label="Treat"
            v-model="foodTreat">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(20)">Continue</button>
      </div>

      <div class="step text-input" id="step19" v-show="step==20">
        <div class="headline">
          WHAT IS YOUR FAVORITE BEVERAGE?
        </div>
        <div>
          <input name="food_treat" type="text" class="textfield optional" placeholder="Beverage" aria-label="Treat"
            v-model="beverage">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(21)">Continue</button>
      </div>

      <div class="step text-input" id="step19" v-show="step==21">
        <div class="headline">
          WHAT IS YOUR FAVORITE STORE?
        </div>
        <div>
          <input name="food_treat" type="text" class="textfield optional" placeholder="Store" aria-label="Treat"
            v-model="store">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(22)">Continue</button>
      </div>



      <!-- <div class="step box-input p downpayment" id="step20" v-show="step==22">
        <div class="headline">
          ARE YOU CONSIDERING BUYING A NEW OR ADDITIONAL PROERTY IN THE NEXT 24 MONTHS?
        </div>
        <div class="box" @click="chooseAdditionalProp('YES')">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="chooseAdditionalProp('NO')">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div> -->
<!-- 
      <div class="step box-input p downpayment" id="step21" v-show="step==23">
        <div class="headline">
          WOULD YOU LIKE TO DISCUSS REFINANCING FOR HOME IMPROVMENTS, DEBT CONSOLIDATION, ELIMINATE MORTGAGE INSURANCE,
          OR CHANGE TERMS OF LOAN?
        </div>
        <div class="box" @click="chooseAdditionalServ('YES')">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="chooseAdditionalServ('NO')">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div> -->

      <!-- <div class="step box-input p downpayment" id="step22" v-show="step==24">
        <div class="headline">
          WE NEED YOUR HELP :) HAVE YOU RUN INTO A FRIEND OR FAMILY LOOKING TO BUY OR REFINANCE THAT WE CAN REACH OUT TO
          HELP?
        </div>
        <div class="box" @click="referalCheck(true)">
          <img class="icon" src="assets/images/yes.png" alt="yes">
          <p class="txt">Yes</p>
        </div>
        <div class="box" @click="referalCheck(false)">
          <img class="icon" src="assets/images/no.png" alt="no">
          <p class="txt">No</p>
        </div>
      </div>

      <div class="step text-input" id="step23" v-show="step==25">
        <div class="headline">
          THIER NAME AND EMAIL?
        </div>
        <div>
          <input name="referal_name" type="text" class="textfield optional" placeholder="Name" aria-label="name"
            v-model="referal.name">
          <br>
          <input name="referal_email" type="text" class="textfield optional" placeholder="Email" aria-label="email"
            v-model="referal.email">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(26)">Continue</button>
      </div> -->




      <div class="step text-input" id="step24" v-show="step==22">
        <div class="headline">
          YOUR NAME?
        </div>
        <div>
          <input name="first_name" type="text" class="textfield optional" placeholder="First Name"
            aria-label="First Name" v-model="profile.firstName">
          <br>
          <input name="last_name" type="text" class="textfield optional" placeholder="Last Name" aria-label="Last Name"
            v-model="profile.lastName">
          <br>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(27)">Continue</button>
      </div>





      <div class="step" id="step25" v-show="step==27">
        <div class="headline">
          Click the button below to submit!
        </div>
        <div>
          <button name="input" id="submit-form" type="button" class="sub-but fwd" @click="submit"
            :disabled="searchDisabled">Submit</button>
        </div>

      </div>

      <div class="step last" id="step26" v-show="step==28">
        <div>
          <h1>Thank you for submitting your information.<br><br>
            We will contact you shortly</h1>
        </div>
      </div>

      <div class="arrow-container" v-show="arrowShow"><a class="arrow-btn back" aria-label="back"><i
            class="fa fa-arrow-circle-left fa-2x" @click="goBefore"></i></a></div>

    </div>
    <!-- <div class="arrow-container"><a href="#" class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x"></i></a> <a href="#" class="arrow-btn fwd" aria-label="next"><i class="fwd fa fa-arrow-circle-right fa-2x"></i></a></div> -->

  </div>
</template>

<script>
const axios = require('axios');
export default {
  name: 'Content',
  data: () => ({
    step: 1,
    searchDisabled: false,
    profile: {
      firstName: "",
      lastName: "",
    },
    child: {
      childName1: "",
      childBirthday1: "",
      childName2: "",
      childBirthday2: "",
      childName3: "",
      childBirthday3: "",
      childName4: "",
      childBirthday4: "",
      childName5: "",
      childBirthday5: "",
    },
    reachMethod: 0,
    armyServed: false,
    armyBranch: "",
    spouseName: "",
    weddingDate: "",
    spouseBirthday: "",
    favoriteCharity: "",
    collageTeam: "",
    nflTeam: "",
    favoriteHobby1: "",
    favoriteHobby2: "",
    favoriteHobby3: "",
    favoriteLunch: "",
    favoriteDinner: "",
    foodTreat: "",
    beverage: "", 
    store: "", 
    additionalProperty: "",
    additionalService: "",
    referal: {
      name: "", 
      email: "",
    },
    marriedFlag: false,
    childFlag: false,
    collageFlag: false,
    nflFlag: false,
    referalFlag: false,
  }),
  computed: {
    arrowShow() {
      return this.step != 1 && this.step != 27 && this.step != 26
    }
  },
  methods: {
    goNext(step) {
      // sample *** (step===10 && this.estimatedPurchasePrice === "") || (step===11 && (this.zipFinder === null || this.zipFinder == 0)) ***
      if ((step === 27 && this.profile.firstName === "" && this.profile.lastName === "") ){
        alert("Please Complete Required Fields")
        return
      }
      this.step = step
    },
    // newly added 
    goBefore() {
      if(this.step === 8 && !this.armyServed) this.step = this.step - 2
      else this.step--
    },
    chooseReachMethod(number) {
      this.reachMethod = number
      this.goNext(3)
    },
    servedArmy(flag) {
      this.armyServed = flag
      var nextStep = flag? 4 : 5
      this.goNext(nextStep)
    },
    chooseArmyBranch(number) {
      this.armyBranch = number
      this.goNext(5)
    },
    marriedCheck(flag){
      this.marriedFlag = flag
      var nextStep = flag? 6 : 9
      this.goNext(nextStep)
    },
    childCheck(flag){
      this.childFlag = flag
      var nextStep = flag? 10 : 11
      this.goNext(nextStep)
    },
    cFbCheck(flag){
      this.collageFlag = flag
      var nextStep = flag? 13 : 14
      this.goNext(nextStep)
    },
    nflCheck(flag){
      this.nflFlag = flag
      var nextStep = flag? 15 : 16
      this.goNext(nextStep)
    },
    referalCheck(flag){
      this.referalFlag = flag
      var nextStep = flag? 25 : 26
      this.goNext(nextStep)
    },
    chooseAdditionalProp(ans){
      this.additionalProperty = ans
      this.goNext(23)
    },
    chooseAdditionalServ(ans){
      this.additionalService = ans
      this.goNext(24)
    },
    submit() {
      // var c = confirm("Are you really gonna submit?")
      var c = 1
      if(c){
        this.searchDisabled = true;
        const data = {
          reachMethod: this.reachMethod,
          armyServed: this.armyServed,
          armyBranch: this.armyBranch,
          profile: this.profile,
          children: this.child,
          spouseName: this.spouseName,
          weddingDate: this.weddingDate,
          spouseBirthday: this.spouseBirthday,
          favoriteCharity: this.favoriteCharity,
          collageTeam: this.collageTeam,
          nflTeam: this.nflTeam,
          favoriteHobby1: this.favoriteHobby1,
          favoriteHobby2: this.favoriteHobby2,
          favoriteHobby3: this.favoriteHobby3,
          favoriteLunch: this.favoriteLunch,
          favoriteDinner: this.favoriteDinner,
          foodTreat: this.foodTreat,
          beverage: this.beverage,
          store: this.store,
          marriedFlag: this.marriedFlag,
          childFlag: this.childFlag,
          collageFlag: this.collageFlag,
          nflFlag: this.nflFlag,
          addProp: this.additionalProperty,
          addServ: this.additionalService,
          referalFlag: this.referalFlag,
          referal: this.referal,
        }
        console.log(data)
        axios.post(process.env.VUE_APP_API_URL, data).then(res=>{
          if(res.data && res.data.res == "success")
          {
            // alert("Email sent")
            // location.reload()
            this.searchDisabled = false
            this.step = 28
          }
          else {
            console.log(res)
          }
        })
      }
    }
  }
}
</script>
